<template>
	<div class="container -mx-8 mb-16 w-full px-0 lg:mx-auto">
		<h3 class="ml-4 text-xl font-medium">Help for all Non-editable Elements</h3>
		<BaseExpansionList :items="helpRoutes">
			<template #content="{ help, label }">
				<component :is="help" class="p-4" :label="label" />
			</template>
		</BaseExpansionList>
	</div>
</template>
<script setup>
import BaseExpansionList from '@/components/ui/BaseExpansionList.vue';
import useEditorStore from '@/stores/editor';
import { EditorViews } from '@/views/MXEditor/views';
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

const helpLists = {
	m1: [
		'agency-details',
		'contact-me',
		'designations',
		'jobs',
		'languages',
		'maps',
		'phone-details',
		'products',
	],
	m2: [
		'agency-details',
		'contact-me',
		'designations',
		'languages',
		'maps',
		'phone-details',
		'products',
	],
};

const editorStore = useEditorStore();
const { productType } = storeToRefs(editorStore);
const helpRoutes = computed(() =>
	EditorViews.filter(({ path }) => helpLists[productType.value].includes(path))
);
</script>
