const EditorViews = [
	{
		path: 'about-me',
		label: 'About Me',
		icon: 'user-pen',

		component: () => import('@/views/MXEditor/editor/AboutMe.vue'),
		help: () => import('@/views/MXEditor/help/AboutMeHelp'),
	},

	{
		path: 'agent-image',
		label: 'Agent Image',
		icon: 'image',

		component: () => import('@/views/MXEditor/editor/AgentProfileImage.vue'),
		help: () => import('@/views/MXEditor/help/ProfileImageHelp'),
	},
	{
		path: 'banner-image',
		label: 'Banner Image',
		icon: 'panorama',

		component: () => import('@/views/MXEditor/editor/BannerImage'),
		help: () => import('@/views/MXEditor/help/BannerImageHelp'),
	},
	{
		path: 'contact-me',
		label: 'Contact Us',
		icon: 'card-account-mail-outline',

		help: () => import('@/views/MXEditor/help/ContactMeHelp'),
	},
	{
		path: 'designations',
		label: 'Designations',
		icon: 'alphabetical',

		help: () => import('@/views/MXEditor/help/DesignationsHelp'),
	},
	{
		path: 'jobs-listing',
		label: 'Jobs Listing',
		icon: 'magnifying-glass-dollar',

		component: () => import('@/views/MXEditor/editor/JobsListing'),
		help: () => import('@/views/MXEditor/help/JobsHelp'),
	},
	{
		path: 'languages',
		label: 'Languages',
		icon: 'translate',

		help: () => import('@/views/MXEditor/help/LanguagesHelp'),
	},
	{
		path: 'maps',
		label: 'Maps',
		icon: 'map',

		help: () => import('@/views/MXEditor/help/MapsHelp'),
	},
	{
		path: 'meta-description',
		label: 'Meta Description',
		header: 'Page Meta Description',
		icon: 'computer',

		component: () => import('@/views/MXEditor/editor/MetaDescription.vue'),
		help: () => import('@/views/MXEditor/help/TitleAndMetaHelp'),
	},
	{
		path: 'mission',
		label: 'Mission',
		icon: 'bullseye',

		component: () => import('@/views/MXEditor/editor/MissionStatement.vue'),
		help: () => import('@/views/MXEditor/help/MissionStatementHelp'),
	},
	{
		path: 'preferred-locations',
		label: 'Preferred Locations',
		icon: 'city',

		component: () => import('@/views/MXEditor/editor/PreferredLocations'),
		help: () => import('@/views/MXEditor/help/PreferredLocationsHelp'),
	},
	{
		path: 'agency-details',
		label: 'Agency Details',
		header: 'Name, Address, and Landmark',
		icon: 'format-color-text',

		help: () => import('@/views/MXEditor/help/AgencyDetailsHelp'),
	},
	{
		path: 'office-hours',
		label: 'Office Hours',
		icon: 'clock',

		component: () => import('@/views/MXEditor/editor/OfficeHours.vue'),
		help: () => import('@/views/MXEditor/help/OfficeHoursHelp'),
	},
	{
		path: 'page-title',
		label: 'Page Title',
		icon: 'file-circle-info',

		component: () => import('@/views/MXEditor/editor/PageTitle'),
		help: () => import('@/views/MXEditor/help/TitleAndMetaHelp'),
	},
	{
		path: 'phone-details',
		label: 'Phone Details',
		header: 'Non-editable Phone Details',
		icon: 'phone',

		help: () => import('@/views/MXEditor/help/PhoneDetailsHelp'),
	},
	{
		path: 'phone-prefs',
		label: 'Phone Prefs',
		header: 'Phone Prefs',
		icon: 'phone',

		component: () => import('@/views/MXEditor/editor/PhonePrefs'),
		help: () => import('@/views/MXEditor/help/PhonePrefsHelp'),
	},
	{
		path: 'products',
		label: 'Products',
		icon: 'package-variant',

		help: () => import('@/views/MXEditor/help/ProductsHelp'),
	},

	{
		path: 'simple-insights',
		label: 'Simple Insights',
		icon: 'newspaper',

		component: () => import('@/views/MXEditor/editor/SimpleInsights'),
		help: () => import('@/views/MXEditor/help/SimpleInsightsHelp'),
	},
	{
		path: 'social',
		label: 'Social Media',
		icon: 'thumbs-up',

		component: () => import('@/views/MXEditor/editor/SocialMedia'),
		help: () => import('@/views/MXEditor/help/SocialHelp'),
	},
	{
		path: 'team',
		label: 'Team - General',
		icon: 'people-group',

		component: () => import('@/views/MXEditor/editor/TeamSettings'),
		help: () => import('@/views/MXEditor/help/TeamHelp'),
	},
	{
		path: 'team-members',
		label: 'Team - Members',
		icon: 'user-plus',

		component: () => import('@/views/MXEditor/editor/TeamMembers'),
		help: () => import('@/views/MXEditor/help/TeamMemberHelp'),
	},
	{
		path: 'office-about-us',
		label: 'About Us',
		icon: 'users',

		component: () => import('@/views/MXEditor/editor/OfficeAboutUs'),
		help: () => import('@/views/MXEditor/help/OfficeAboutUsHelp'),
	},
	{
		path: 'reviews',
		label: 'Reviews',
		icon: 'star',

		component: () => import('@/views/MXEditor/editor/ReviewsPref'),
		help: () => import('@/views/MXEditor/help/ReviewsPrefHelp'),
	},
	{
		path: 'desktop-layout',
		label: 'Desktop Layout',
		icon: 'table-pivot',

		component: () => import('@/views/MXEditor/editor/DesktopLayout'),
		help: () => import('@/views/MXEditor/help/DesktopLayoutHelp'),
	},
	{
		path: 'jobs-tab-url',
		label: "We're Hiring Tab",
		icon: 'person',

		component: () => import('@/views/MXEditor/editor/JobsTabUrl'),
		help: () => import('@/views/MXEditor/help/JobsTabUrlHelp'),
	},
	{
		path: 'locations-image',
		label: 'Locations Image',
		icon: 'panorama',

		component: () => import('@/views/MXEditor/editor/LocationsImage'),
		help: () => import('@/views/MXEditor/help/LocationsImageHelp'),
	},
	{
		path: 'route-update-content',
		label: 'Update Content',
		icon: 'arrows-rotate',

		component: () => import('@/views/MXEditor/editor/UpdateContent'),
		help: () => import('@/views/MXEditor/help/UpdateContentHelp'),
	},
];
EditorViews.sort((a, b) => a.label.localeCompare(b.label));

export { EditorViews };
